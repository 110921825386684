import { AddressSearch, LinkComponent } from '@application/components';
import { UserContext } from '@application/contexts';
import { Routes } from '@application/routes';
import { ReportAggregation, UserRole } from '@domain/graphql.types';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Box, Link, Tooltip, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SiteLookupContext from '../context/SiteLookupContext';
import RadiusFilter from '../filters/RadiusFilter';
import { OnSiteLookupFilterChange, SiteLookupFilters } from '../filters/useSiteLookupFilters';
import DownloadReportMenu from './DownloadReportMenu';

type Props = {
  filters: SiteLookupFilters;
  handleDownloadReport: (aggregation: ReportAggregation) => void;
  isUserOnFreePlan: boolean | undefined;
  onFilterChange: OnSiteLookupFilterChange<keyof SiteLookupFilters>;
  remainingSearches: number | undefined;
};

const SearchBar = ({ filters, handleDownloadReport, isUserOnFreePlan, onFilterChange, remainingSearches }: Props) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLElement>(null);

  const { palette, zIndex } = useTheme();

  const { searchLimit } = useContext(SiteLookupContext);
  const { role } = useContext(UserContext);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const freeUserHasNoSearches = useMemo(() => !!isUserOnFreePlan && (remainingSearches || 0) <= 0, [isUserOnFreePlan, remainingSearches]);

  const isDownloadReportDisabled = useMemo(
    () => freeUserHasNoSearches || !filters.address || !!searchLimit?.exceeded,
    [filters.address, freeUserHasNoSearches, searchLimit],
  );

  const showLinkToSubscription = role === UserRole.Owner;

  return (
    <Box
      position="sticky"
      display="flex"
      gap={3}
      paddingX={3}
      paddingY={2}
      sx={{
        top: 'calc(var(--top-bar-height) + 1px)',
        zIndex: zIndex.fab,
        minHeight: 'var(--search-bar-height)',
        backgroundColor: palette.common.white,
        boxShadow: '0 0 2px rgb(0 0 0 / 20%)',
      }}
    >
      <Box minWidth={{ xs: 300, lg: 400, xl: 460 }}>
        <AddressSearch onSelect={onFilterChange('address')} ref={ref} value={filters.address?.fullAddress || ''} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <RadiusFilter onChange={onFilterChange('radius')} value={filters.radius} />
      </Box>

      {searchLimit?.exceeded && (
        <Tooltip
          arrow
          placement="top"
          sx={{ width: 100 }}
          title={<Trans i18nKey={'siteLookup.searchLimitExceeded'} t={t} values={{ limit: searchLimit.count }} />}
        >
          <WarningAmberOutlined sx={{ alignSelf: 'center', color: 'warning.dark' }} />
        </Tooltip>
      )}

      <Tooltip
        disableInteractive={!freeUserHasNoSearches}
        placement="top"
        title={
          freeUserHasNoSearches ? (
            <Trans
              i18nKey={'siteLookup.downloadReportTooltipLimit'}
              t={t}
              components={{
                LearnMore: showLinkToSubscription ? (
                  <Link color="inherit" component={LinkComponent} href={Routes.TENANT_SUBSCRIPTION}>
                    {t('siteLookup.learnMore')}
                  </Link>
                ) : (
                  <></>
                ),
              }}
            />
          ) : null
        }
      >
        <Box sx={{ alignSelf: 'center', height: 40, minWidth: 'fit-content' }}>
          <DownloadReportMenu onDownloadReport={handleDownloadReport} isDownloadReportDisabled={isDownloadReportDisabled} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default SearchBar;
