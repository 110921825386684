import { LinkComponent } from '@application/components';
import { UserContext } from '@application/contexts';
import { SubscriptionPlan } from '@domain/graphql.types';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const TenantSubscription = () => {
  const { t } = useTranslation();

  const { typography } = useTheme();

  const { isOnFreePlan, tenantSubscription } = useContext(UserContext);

  return tenantSubscription?.plan === SubscriptionPlan.Free ? (
    <Stack gap={2} alignItems="center" marginTop={2}>
      <Typography sx={{ width: 380, textAlign: 'center', fontSize: typography.h4 }}>{t('tenant.subscription.customPlan')}</Typography>

      <Button
        variant="contained"
        LinkComponent={LinkComponent}
        href={import.meta.env.VITE_REACT_APP_PAREN_CONTACT}
        target="_blank"
        sx={{ width: 'fit-content' }}
      >
        {t('tenant.subscription.contactUs')}
      </Button>
    </Stack>
  ) : (
    <Stack gap={2} alignItems="center" marginTop={2}>
      <Typography>{t('tenant.subscription.currentPlan', { plan: tenantSubscription?.plan })}</Typography>

      <Typography>
        {t(`tenant.remainingDownloads.${isOnFreePlan ? 'free' : 'paid'}`, { count: tenantSubscription?.remainingSearches })}
        {!!tenantSubscription?.extraSearches && (
          <>
            &nbsp;
            {`(${t('tenant.extraDownloads', { count: tenantSubscription.extraSearches })})`}
          </>
        )}
      </Typography>

      <Button
        variant="contained"
        LinkComponent={LinkComponent}
        href={import.meta.env.VITE_REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}
        target="_blank"
        sx={{ width: 'fit-content' }}
      >
        {t('tenant.subscription.manage')}
      </Button>
    </Stack>
  );
};

export default TenantSubscription;
